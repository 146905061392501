import React, { useEffect } from 'react'
import { useUniqueId } from '@te-digi/styleguide'

import { LinkMock, LinkMockTargetBlank } from '../../components/LinkMock'

import { HeaderSearchMobileHTMLExample } from './HeaderSearchHTMLExample'
import IconHTMLExample from './IconHTMLExample'
import LogoKayttoohjeetHTMLExample from './LogoKayttoohjeetHTMLExample'
import LanguageMenu from './LanguageMenu'

export const HeaderMobileNavButton = ({ id }: { id: string }) => (
  <button
    aria-label="Käyttöohjeet päävalikko"
    aria-controls={id}
    aria-expanded="false"
    className="btn button-mobile-menu-opener header-toggler mobile-nav-button-js"
    type="button"
  >
    <span className="mobile-menu-opener-content mobile-menu-opener-content-instructions">
      <span className="mobile-menu-opener-icon">
        <IconHTMLExample
          className="header-toggler-icon-x"
          name="XIcon"
          size="lg"
        />
        <IconHTMLExample
          className="header-toggler-icon-menu"
          name="MenuIcon"
          size="lg"
        />
      </span>
      <span className="mobile-menu-opener-label">
        <span className="header-toggler-label-x">Sulje valikko</span>
        <span className="header-toggler-label-menu">Valikko</span>
      </span>
    </span>
  </button>
)

const HeaderKayttoohjeetMobileHTMLExample = () => {
  const mobileNavId = useUniqueId('mobile-nav')
  const languageMenuId = useUniqueId('language-menu')

  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
  }, [])

  return (
    <>
      <div className="header-area-nav-container header-area-nav-container-instructions">
        <div className="header-logo-container header-logo-container-instructions">
          <LinkMock>
            <LogoKayttoohjeetHTMLExample />
          </LinkMock>
        </div>
        <HeaderMobileNavButton id={mobileNavId} />
      </div>

      <div
        className="mobile-nav"
        id={mobileNavId}
      >
        <div className="mobile-nav-search">
          {HeaderSearchMobileHTMLExample()}
        </div>

        <div className="mobile-nav-tools">
          <LinkMockTargetBlank className="mobile-instructions-link">
            Anna palautetta
            <IconHTMLExample
              name="ExternalLinkIcon"
              className="header-toolbar-instructions-icon"
              size="lg"
              ariaLabel="Avautuu uuteen välilehteen"
            />
          </LinkMockTargetBlank>
          <div className="mobile-nav-language-select">
            <LanguageMenu
              buttonClass="btn-plain-primary"
              handle={languageMenuId}
            />
          </div>
        </div>

        <nav>
          <ul
            className="mobile-menu-list"
            id="mobile-nav-33"
          >
            <li className="mobile-menu-list-item">
              <span className="mobile-menu-item active">
                <LinkMock className="mobile-menu-link mobile-menu-link-with-button">
                  <IconHTMLExample
                    name="UserIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Henkilöasiakkaat
                </LinkMock>
                <button
                  aria-label="Henkilöasiakkaat: Näytä tai piilota alasivut"
                  aria-controls="mobile-nav-44"
                  aria-expanded="false"
                  className="btn btn-plain-primary mobile-menu-toggler mobile-menu-button"
                  data-toggle="collapse"
                  type="button"
                >
                  <span className="btn-content">
                    <IconHTMLExample
                      className="btn-icon mobile-menu-toggler-icon-expand"
                      name="ExpandIcon"
                      size="lg"
                    />
                    <IconHTMLExample
                      className="btn-icon mobile-menu-toggler-icon-collapse"
                      name="CollapseIcon"
                      size="lg"
                    />
                  </span>
                </button>
              </span>
              <ul
                className="mobile-menu-list collapse"
                id="mobile-nav-44"
              >
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link">
                      Työn etsiminen ja osaamisen kehittäminen
                    </LinkMock>
                  </span>
                </li>
                <li className="mobile-menu-list-item">
                  <span className="mobile-menu-item">
                    <LinkMock className="mobile-menu-link">
                      Asiointi työvoimaviranomaisen kanssa
                    </LinkMock>
                  </span>
                </li>
              </ul>
            </li>
            <li className="mobile-menu-list-item">
              <span className="mobile-menu-item">
                <LinkMock className="mobile-menu-link mobile-menu-link-with-button">
                  <IconHTMLExample
                    name="GroupIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Työnantajat ja yrittäjät
                </LinkMock>
              </span>
            </li>
            <li className="mobile-menu-list-item">
              <span className="mobile-menu-item">
                <LinkMock className="mobile-menu-link mobile-menu-link-with-button">
                  <IconHTMLExample
                    name="QuestionIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Usein kysytyt kysymykset
                </LinkMock>
              </span>
            </li>
            <li className="mobile-menu-list-item">
              <span className="mobile-menu-item">
                <LinkMock className="mobile-menu-link mobile-menu-link-with-button">
                  <IconHTMLExample
                    name="CustomerServiceIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Asiakaspalvelu
                </LinkMock>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export { HeaderKayttoohjeetMobileHTMLExample }
