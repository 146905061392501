import React, { useEffect } from 'react'
import { useUniqueId } from '@te-digi/styleguide'

import { LinkMock, LinkMockTargetBlank } from '../../components/LinkMock'

import { HeaderAsiointiMobileHTMLExample } from './HeaderAsiointiMobileHTMLExample'
import { HeaderSearchAsiointiHTMLExample } from './HeaderSearchHTMLExample'

import IconHTMLExample from './IconHTMLExample'
import LogoHTMLExample from './LogoHTMLExample'
import LanguageMenu from './LanguageMenu'

const HeaderAsiointiHTMLExample = () => {
  const mobileNavId = useUniqueId('mobile-nav')

  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
    // @ts-expect-error
    window.StyleGuide.initializeLanguageSelection()
    // @ts-expect-error
    window.StyleGuide.initializeSearchHide()
    // @ts-expect-error
    window.StyleGuide.initializeNavButtons()
    // @ts-expect-error
    window.StyleGuide.initializeMobileNav()
  }, [])

  return (
    <header className="header-background header-content header-asiointi">
      <a
        className="btn btn-outline-primary skip-link"
        href="#content"
      >
        <span className="btn-content">
          <span className="btn-label">Hyppää sisältöön</span>
        </span>
      </a>
      <div className="header-toolbar">
        <button
          className="btn btn-primary btn-plain-white header-toolbar-button header-toolbar-desktop-tool"
          aria-controls="search-bar-container"
          aria-expanded="false"
          data-toggle="collapse"
          id="header-search-button"
          type="button"
        >
          <span className="btn-content">
            <IconHTMLExample
              name="SearchIcon"
              className="btn-icon-left"
              size="lg"
            />
            <span className="btn-label">Haku</span>
          </span>
        </button>
        <div className="header-divider header-toolbar-desktop-tool" />

        <LinkMockTargetBlank className="toolbar-instructions-link header-toolbar-desktop-tool">
          Ohjeet ja tuki
          <IconHTMLExample
            name="ExternalLinkIcon"
            className="header-toolbar-instructions-icon"
            size="lg"
            ariaLabel="Avautuu uuteen välilehteen"
          />
        </LinkMockTargetBlank>

        <div className="header-divider header-toolbar-desktop-tool" />

        <div className="header-toolbar-desktop-tool mr-md">
          <LanguageMenu buttonClass="btn-primary btn-square header-toolbar-button" />
        </div>

        <div className="dropdown page-header-user-dropdown">
          <button
            aria-controls="user-menu"
            aria-expanded="false"
            aria-haspopup="true"
            className="btn page-header-user-button dropdown-toggle header-toolbar-login-button"
            data-toggle="dropdown"
            id="user-menu-button"
            type="button"
          >
            <span className="btn-content">
              <IconHTMLExample
                name="UserIcon"
                className="btn-icon-left"
                size="lg"
              />
              <span className="btn-label">Kirjaudu sisään</span>
            </span>
          </button>
          <div
            aria-labelledby="user-menu-button"
            className="dropdown-menu dropdown-menu-custom page-header-user-menu"
            id="user-menu"
          >
            <div className="dropdown-section">
              <p className="lead mb-0">Matti Meikäläinen</p>
              <p className="paragraph-light mb-0">Matin Nakkikioski</p>
            </div>
          </div>
        </div>
      </div>

      <div className="header-mobile">
        <HeaderAsiointiMobileHTMLExample id={mobileNavId} />
      </div>
      <div className="header-desktop">
        <div
          className="search-bar-container collapse"
          id="search-bar-container"
        >
          <HeaderSearchAsiointiHTMLExample />
        </div>
        <div className="header-area-nav-container">
          <div className="header-logo-container">
            <LinkMock>
              <LogoHTMLExample />
            </LinkMock>
          </div>
          <nav
            aria-label="Päänavigaatio"
            className="area-nav"
          >
            <ul className="area-nav-list">
              <li className="area-nav-item">
                <LinkMock
                  className="area-nav-link active"
                  aria-current="true"
                  data-content="Henkilöasiakkaat"
                  id="main-nav-henkiloasiakkaat"
                >
                  <IconHTMLExample
                    name="UserIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Henkilöasiakkaat
                </LinkMock>
              </li>
              <li className="area-nav-item">
                <LinkMock
                  className="area-nav-link"
                  data-content="Työnantajat ja yrittäjät"
                  id="area-nav-tyonantajat-ja-yrittajat"
                >
                  <IconHTMLExample
                    name="GroupIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Työnantajat ja yrittäjät
                </LinkMock>
              </li>
              <li className="area-nav-item">
                <LinkMock
                  className="area-nav-link"
                  data-content="Aluesivut"
                  id="area-nav-aluesivut"
                >
                  <IconHTMLExample
                    name="LocationIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Aluesivut
                </LinkMock>
              </li>
              <li className="area-nav-item">
                <LinkMock
                  className="area-nav-link"
                  data-content="Ammatit ja työllisyys"
                  id="area-nav-tietoa-tyollisyydesta"
                >
                  <IconHTMLExample
                    name="AlertCircleIcon"
                    className="page-header-item-icon"
                    size="lg"
                  />
                  Ammatit ja työllisyys
                </LinkMock>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header-main-nav-container">
          <nav
            aria-labelledby="main-nav-henkiloasiakkaat"
            className="main-nav"
          >
            <ul className="main-nav-list">
              <li className="main-nav-item">
                <LinkMock
                  className="main-nav-link active"
                  aria-current="page"
                >
                  Avoimet työpaikat
                </LinkMock>
              </li>
              <li className="main-nav-item">
                <LinkMock className="main-nav-link">
                  Koulutukset ja palvelut
                </LinkMock>
              </li>
              <li className="main-nav-item">
                <LinkMock
                  className="main-nav-link"
                  id="tietoa_tyoelamasta"
                >
                  Tietoa työelämästä
                </LinkMock>
              </li>
            </ul>
            <ul className="main-nav-list special">
              <li className="main-nav-item">
                <button
                  className="btn btn-plain-primary dropdown-nav-toggler"
                  type="button"
                  aria-expanded="false"
                  aria-controls="navmenu_tyopolku"
                  id="button_tyopolku"
                >
                  <span className="btn-content">
                    <IconHTMLExample
                      name="SpeechBubbleFillIcon"
                      className="btn-icon-left"
                      size="lg"
                    />
                    <span className="btn-label">Oma työpolku</span>
                    <IconHTMLExample
                      name="CollapseIcon"
                      className="btn-icon-right dropdown-nav-toggler-collapse"
                      size="lg"
                    />
                    <IconHTMLExample
                      name="ExpandIcon"
                      className="btn-icon-right dropdown-nav-toggler-expand"
                      size="lg"
                    />
                  </span>
                </button>
                <div className="third-level-navigation">
                  <div className="dropdown-nav">
                    <nav
                      aria-labelledby="button_tyopolku"
                      id="navmenu_tyopolku"
                      className="navmenu"
                    >
                      <div className="navmenu-container">
                        <div className="navmenu-icon-container">
                          <IconHTMLExample
                            name="SpeechBubbleFillIcon"
                            size="xxxl"
                            color="primary"
                          />
                        </div>
                        <div className="navmenu-intro-container">
                          <h4 className="navmenu-intro-header">Oma työpolku</h4>
                          <p className="text-neutral-9">
                            Ota kaikki irti työmarkkinatorista. Luo anonyymin
                            työnhakuprofiili, jolla löydät juuri sinulle sopivia
                            työpaikkoja.
                          </p>
                        </div>
                        <div className="navmenu-links-container">
                          <LinkMock className="navmenu-link">
                            Osion etusivu
                          </LinkMock>
                          <LinkMock className="navmenu-link">
                            Työnhakuprofiili
                          </LinkMock>
                          <LinkMock className="navmenu-link">
                            Suositellut työpaikat
                          </LinkMock>
                          <LinkMock className="navmenu-link">Suosikit</LinkMock>
                          <LinkMock className="navmenu-link">
                            Työllistä kotitaloutena
                          </LinkMock>
                          <LinkMock className="navmenu-link">
                            Liiketoimintasuunnitelma
                          </LinkMock>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </li>
              <li className="main-nav-item">
                <button
                  className="btn btn-plain-primary dropdown-nav-toggler"
                  type="button"
                  aria-expanded="false"
                  aria-controls="navmenu_asiointi"
                  id="button_asiointi"
                >
                  <span className="btn-content">
                    <IconHTMLExample
                      name="TePalvelutFillIcon"
                      className="btn-icon-left icon-brand"
                      size="lg"
                    />
                    <span className="btn-label">Asiointi</span>
                    <IconHTMLExample
                      name="CollapseIcon"
                      className="btn-icon-right dropdown-nav-toggler-collapse"
                      size="lg"
                    />
                    <IconHTMLExample
                      name="ExpandIcon"
                      className="btn-icon-right dropdown-nav-toggler-expand"
                      size="lg"
                    />
                  </span>
                </button>
                <div className="third-level-navigation">
                  <div className="dropdown-nav">
                    <nav
                      aria-labelledby="button_asiointi"
                      id="navmenu_asiointi"
                      className="navmenu"
                    >
                      <div className="navmenu-container">
                        <div className="navmenu-icon-container">
                          <IconHTMLExample
                            name="SpeechBubbleFillIcon"
                            size="xxxl"
                            color="primary"
                          />
                        </div>
                        <div className="navmenu-intro-container">
                          <h4 className="navmenu-intro-header">Asiointi</h4>
                          <p className="text-neutral-9">Tekstiä</p>
                        </div>
                        <div className="navmenu-links-container">
                          <LinkMock className="navmenu-link">
                            Asiointi etusivu
                          </LinkMock>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
          <div className="third-level-navigation">
            <nav
              className="secondary-nav"
              aria-labelledby="tietoa_tyoelamasta"
            >
              <ul className="secondary-nav-list ">
                <li className="secondary-nav-item ">
                  <a
                    className="secondary-nav-link "
                    data-content="Työnhaku"
                    href="/henkiloasiakkaat/tyonhaku"
                  >
                    Työnhaku
                  </a>
                </li>
                <li className="secondary-nav-item ">
                  <a
                    className="secondary-nav-link "
                    data-content="Koulutus ja opiskelu"
                    href="/henkiloasiakkaat/koulutus"
                  >
                    Koulutus ja opiskelu
                  </a>
                </li>
                <li className="secondary-nav-item ">
                  <a
                    className="secondary-nav-link "
                    data-content="Ura ja työsuhde"
                    href="/henkiloasiakkaat/ura-ja-tyosuhde"
                  >
                    Ura ja työsuhde
                  </a>
                </li>
                <li className="secondary-nav-item ">
                  <a
                    className="secondary-nav-link "
                    data-content="Itsensä työllistäminen ja yrittäjyys"
                    href="/henkiloasiakkaat/itsensa-tyollistaminen"
                  >
                    Itsensä työllistäminen ja yrittäjyys
                  </a>
                </li>
                <li className="secondary-nav-item ">
                  <a
                    className="secondary-nav-link "
                    data-content="Kansainvälisyys"
                    href="/henkiloasiakkaat/kansainvalisyys"
                  >
                    Kansainvälisyys
                  </a>
                </li>
                <li className="secondary-nav-item ">
                  <a
                    className="secondary-nav-link "
                    data-content="Työttömyysturva"
                    href="/henkiloasiakkaat/tyottomyysturva"
                  >
                    Työttömyysturva
                  </a>
                </li>
                <li className="secondary-nav-item ">
                  <a
                    className="secondary-nav-link "
                    data-content="Työttömyys"
                    href="/henkiloasiakkaat/tyottomyys"
                  >
                    Työttömyys
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export { HeaderAsiointiHTMLExample }
